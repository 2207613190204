import * as React from 'react';
import '../styles/App.less';
import {setUTMCookie} from '../utils/CookieUtil';
import {Helmet} from 'react-helmet';
import {PageProps} from 'gatsby';
import {OrganizationSelection} from '../components/OrganizationSelection/OrganizationSelection';
import {Auth} from '../components/Auth/Auth';
import {NavLayout} from '../components/Layout/NavLayout';

const IndexPage = (props: PageProps) => {
  setUTMCookie(props);

  return (
    <>
      <Helmet htmlAttributes={{lang: 'en'}}>
        <meta charSet='utf-8' />
        <meta
          name='description'
          content={
            'Cribl Cloud is the leading data observability platform in the world. Try Stream in the cloud today!'
          }
        />
        <title>Cribl.Cloud </title>
        <link rel='canonical' href='https://cribl.cloud' />
      </Helmet>

      <Auth>
        <NavLayout {...props}>
          <OrganizationSelection {...props} />
        </NavLayout>
      </Auth>
    </>
  );
};

export default IndexPage;

import * as React from 'react';
import {Spin} from 'antd';
import {nanoid} from 'nanoid';
import {useAuth} from './AuthContext';
import {navigate} from 'gatsby';

const clearNonceReturnTo = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const value = localStorage.key(i);
    if (/^nonceReturnTo_/.test(value)) {
      localStorage.removeItem(value);
    }
  }
};

export const Auth = ({children}) => {
  const {isAuthenticated, isLoading, loginWithRedirect, user, getAccessTokenSilently} = useAuth();

  React.useEffect(() => {
    if (isLoading) return;
    if (!isAuthenticated) {
      clearNonceReturnTo();
      // generate a one-time use key for storing the return url
      // doing this so make it difficult for a malicious actor to set the redirect url
      const nonceReturnTo = `nonceReturnTo_${nanoid()}`;
      // store current location in localstorage so the user can be redirected back after logging in
      localStorage.setItem(nonceReturnTo, window?.location.pathname);
      void loginWithRedirect({appState: {nonceReturnTo}});
    } else if (!user?.email_verified && user?.sub?.split('|')?.[0] === 'auth0') {
      // double check user for email_verified flag with auth0 server (ignore cache)
      (async () => {
        await getAccessTokenSilently({ignoreCache: true});
        if (!user?.email_verified) {
          await navigate('/verify');
          return;
        }
      })();
    }
  }, [isLoading]);

  if (!isAuthenticated) {
    return <Spin size='large' className={'spinner-auth0'} />;
  }

  return children;
};
